import React from 'react'
import ProjectsList from '../components/ProjectsList'

const Projects = () => {
  return (
    <>
      <ProjectsList />
    </>
  )
}

export default Projects
